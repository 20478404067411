import { defineStore } from 'pinia'
import { useDisplay } from 'vuetify'

/**
 * `window` 객체 기반으로 판단 가능한 값들을 저장하기 위한 Vuex 모듈. 당연하겠지만 `window` 객체가 필요하므로 클라이언트
 * 측에서만 작동한다. 즉, SSR에서 사용하면 에러가 발생하므로 주의할 것!
 */

export type WindowSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const useDYWindowStore = defineStore('nuxt3-module-doyac-window', () => {
  const display = useDisplay()

  const canGoBack = ref(false)
  const canGoForward = ref(false)

  const isDesktop = computed(() => {
    return display.platform.value.win || display.platform.value.mac || (!display.platform.value.android && display.platform.value.linux)
  })

  const isMobileSize = computed(() => display.mobile.value)

  //display의 prop들을 바로 리턴해버리면, 제대로 값을 반응하지 못함. computed값을 리턴해줘야 먹음
  const height = computed(() => display.height.value)
  const width = computed(() => display.width.value)
  const size = computed(() => display.name.value)

  return {
    windowHeight: height, windowWidth: width, size: size, isDesktop, canGoBack, canGoForward, isMobileSize
  }
})
